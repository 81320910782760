import React from "react"
import { mediaQueryList } from "../helpers"
import { useMedia } from "react-use"
import { useIsClient } from "./use-is-client"

export const useMobileImageSelector = () => {
  const isClient = useIsClient()
  const mediaQueryMatch = useMedia(mediaQueryList({ mediaPrefix: false })[1])
  const desktopImageSelector = desktopImage => desktopImage || ""
  const mobileImageSelector = (desktopImage, mobileImage) =>
    mobileImage || desktopImage || ""
  // const [isMobile, setIsMobile] = React.useState(false)
  // let isMobile
  // if(!mediaQueryMatch){
  //   setIsMobile(true)
  // }
  // React.useEffect(() => {
  //   // if (isClient) {
  //     if (!mediaQueryMatch) {
  //       console.log("ttrue")
  //       // setIsMobile(true)
  //       isMobile = true
  //     } else {
  //       isMobile= false
  //     }
  //   // }
  // }, [ mediaQueryMatch])
  // console.log(mediaQueryMatch)
  return  mediaQueryMatch ? desktopImageSelector : mobileImageSelector
}
